.blog{
  .navigation{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;

    select{
      max-width: 100%;
    }
  }

}